import CryptoJS from 'crypto-js'
import {Version} from "@/utils/axios";

//DES加密 Pkcs7填充方式
const encryptByDES = (message, key = 'dawei2022!@#_jhstfrd097') => {
  const keyHex = CryptoJS.enc.Utf8.parse(key);
  const encrypted = CryptoJS.TripleDES.encrypt(message, keyHex, {
    mode: CryptoJS.mode.ECB, // CBC模式
    padding: CryptoJS.pad.Pkcs7, // pkcs7padding 填充方式
    // iv: CryptoJS.enc.Utf8.parse(iv) // 设置偏移量（若 ECB 模式则删除该行）
  });
  // return encrypted.ciphertext.toString(); // 返回 hex 编码格式
  return encrypted.toString(); // 返回 base64 编码格式
}

//DES解密
const decryptByDES = (ciphertext, key = 'dawei2022!@#_jhstfrd097') => {
  if (Version === 1) {
    return ciphertext
  }
  let ciphertextArr = []
  if (typeof ciphertext === 'object') {
    ciphertext.forEach(v => {
      const keyHex = CryptoJS.enc.Utf8.parse(key);
      const decrypted = CryptoJS.TripleDES.decrypt({
        // ciphertext: CryptoJS.enc.Hex.parse(ciphertext) // hex 编码解密
        ciphertext: CryptoJS.enc.Base64.parse(v) // base64 编码解密
      }, keyHex, {
        mode: CryptoJS.mode.ECB, // CBC模式
        padding: CryptoJS.pad.Pkcs7, // pkcs7padding 填充方式
        // iv: CryptoJS.enc.Utf8.parse(iv) // 设置偏移量（若 ECB 模式则删除该行）
      });

      ciphertextArr.push(decrypted.toString(CryptoJS.enc.Utf8))
      return ciphertextArr
    })
    return ciphertextArr
  } else {
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const decrypted = CryptoJS.TripleDES.decrypt({
      // ciphertext: CryptoJS.enc.Hex.parse(ciphertext) // hex 编码解密
      ciphertext: CryptoJS.enc.Base64.parse(ciphertext) // base64 编码解密
    }, keyHex, {
      mode: CryptoJS.mode.ECB, // CBC模式
      padding: CryptoJS.pad.Pkcs7, // pkcs7padding 填充方式
      // iv: CryptoJS.enc.Utf8.parse(iv) // 设置偏移量（若 ECB 模式则删除该行）
    });
    let a;
    try {
      a = decrypted.toString(CryptoJS.enc.Utf8)
    } catch (e) {
      console.log(e);
    }
    return a; // 返回 base64 编码格式
    // console.log(decrypted.toString(CryptoJS.enc.Utf8))
  }


}


export {encryptByDES, decryptByDES}
